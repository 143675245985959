import type {} from "@mui/lab/themeAugmentation";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const appTheme = createTheme({
  palette: {
    primary: {
      main: "#134A8E",
    },
    secondary: {
      main: "#1D2D5C",
    },
  },
});

export default responsiveFontSizes(appTheme);
