import { About, Career, DoesNotExist } from "./pages";

const routes = [
  {
    path: "/",
    component: About,
  },
  {
    path: "/career",
    component: Career,
  },
  {
    path: "*",
    component: DoesNotExist,
  },
];

export default routes;
