import { ReactElement } from "react";

import Masonry from "@mui/lab/Masonry";
import {
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  CardMedia,
  Theme,
} from "@mui/material";
import { withStyles, WithStyles } from "@mui/styles";
import PropTypes from "prop-types";

import HeaderCard from "../headerCard";

const styles = (theme: Theme) => ({
  sectionDivider: {
    margin: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      "margin-left": theme.spacing(10),
      "margin-right": theme.spacing(10),
    },
    [theme.breakpoints.up("md")]: {
      "margin-left": theme.spacing(20),
      "margin-right": theme.spacing(20),
    },
    [theme.breakpoints.up("lg")]: {
      "margin-left": theme.spacing(30),
      "margin-right": theme.spacing(30),
    },
  },
  card: {},
  cardPhoto: {
    height: "auto",
    maxWidth: "100%",
    "object-fit": "contain",
  },
});

function About(props: WithStyles<typeof styles>): ReactElement {
  const { classes } = props;
  return (
    <>
      <HeaderCard />
      <Typography component="h2" variant="h2" color="primary">
        About me
      </Typography>
      <Grid container justifyContent="center">
        <Grid item md={6} xs={10} sm={8}>
          <Typography component="p" paragraph variant="body1" align="justify">
            Welcome. I&apos;m a software professional currently residing in San
            Francisco. I am currently working in the food robotics space.
            Previous experience is in the Consumer Growth space across mobile
            apps and websites.
          </Typography>
          <Typography component="p" paragraph variant="body1" align="justify">
            Outside of work, I enjoy sports, video games, reading, cooking, and
            travelling. Feel free to keep reading below to see my thoughts on
            each one of those areas.
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.sectionDivider} />
      <Typography component="h3" variant="h3" color="primary">
        Interests
      </Typography>
      {/** TODO Update to ImageList https://mui.com/material-ui/react-image-list/ */}
      <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={4}>
        {/** Just put a collage of: NBA: current photo, NHL: paper bag photo, NFL: Nearly crying during championship */}
        <Card className={classes.card}>
          <CardMedia component="picture">
            <source srcSet="media/raptors_finals.webp" type="image/webp" />
            <source srcSet="media/raptors_finals.png" type="image/png" />
            <img
              className={classes.cardPhoto}
              src="media/raptors_finals.png"
              alt="Raptors winning NBA Championship"
            />
          </CardMedia>
          <CardContent>
            <Typography variant="body2" align="justify">
              I had the privilege of being able to attend games 3 and 6 of the
              2019 championship run by the Toronto Raptors.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardMedia component="picture">
            <source srcSet="media/ski_trip.webp" type="image/webp" />
            <source srcSet="media/ski_trip.png" type="image/png" />
            <img
              className={classes.cardPhoto}
              src="media/ski_trip.png"
              alt="Skiing in Whistler"
            />
          </CardMedia>
          <CardContent>
            <Typography variant="body2" align="justify">
              Skiing during a whiteout in Whistler.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardMedia component="picture">
            <source srcSet="media/luna.webp" type="image/webp" />
            <source srcSet="media/luna.png" type="image/png" />
            <img
              className={classes.cardPhoto}
              src="media/luna.png"
              alt="My pet cat, Luna"
            />
          </CardMedia>
          <CardContent>
            <Typography variant="body2" align="justify">
              Luna.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardMedia component="picture">
            <source srcSet="media/seinfeld.webp" type="image/webp" />
            <source srcSet="media/seinfeld.jpg" type="image/jpg" />
            <img
              className={classes.cardPhoto}
              src="media/seinfeld.jpg"
              alt="Meeting cast from Seinfeld"
            />
          </CardMedia>
          <CardContent>
            <Typography variant="body2" align="justify">
              Meeting Larry Thomas at Clusterfest.
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardMedia component="picture">
            <source srcSet="media/kenya.webp" type="image/webp" />
            <source srcSet="media/kenya.jpg" type="image/jpg" />
            <img
              className={classes.cardPhoto}
              src="media/kenya.jpg"
              alt="Elephant from the Massai Mara"
            />
          </CardMedia>
          <CardContent>
            <Typography variant="body2" align="justify">
              Elephant photo from my time in the Maasai Mara reserve.
            </Typography>
          </CardContent>
        </Card>
      </Masonry>
      {/** recent good reads: first law trilogy, 3 body problem, Dune,  */}
    </>
  );
}

About.propTypes = {
  classes: PropTypes.shape({
    sectionDivider: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    cardPhoto: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(About);
