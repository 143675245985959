import { ReactElement } from "react";

import { AppBar, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import appTheme from "./appTheme";
import AppFooter from "./footer";
import NavigationTabs from "./header";
import routes from "../routes";

import "./app.css";

function App(): ReactElement {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <BrowserRouter>
          <div className="App">
            <AppBar color="transparent" position="static" enableColorOnDark>
              <NavigationTabs />
            </AppBar>
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  Component={route.component}
                />
              ))}
            </Routes>
            <AppFooter />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
