import { StrictMode } from "react";

import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById("root")!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

serviceWorker.register();
