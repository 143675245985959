import { Grid, Typography, Paper, Theme } from "@mui/material";
import { withStyles } from "@mui/styles";
import PropTypes from "prop-types";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  image: {
    "max-width": "100%",
  },
});

interface DoesNotExistProps {
  classes: {
    root: string;
    image: string;
  };
}

function DoesNotExist({ classes }: DoesNotExistProps) {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={6}>
        <Paper elevation={12} className={classes.root}>
          <Typography component="h2" variant="h2" color="primary">
            404 - Does Not Exist
          </Typography>
          <Typography component="p" variant="body1">
            Hey, it looks like the page you were looking for does not exist. So
            enjoy a dog instead!
          </Typography>
          <picture>
            <source srcSet="media/jewels_pineapple.webp" type="image/webp" />
            <source srcSet="media/jewels_pineapple.jpg" type="image/jpeg" />
            <img
              src="media/jewels_pineapple.jpg"
              alt="Jewels with a pineapple!"
              className={classes.image}
            />
          </picture>
        </Paper>
      </Grid>
    </Grid>
  );
}
DoesNotExist.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(DoesNotExist);
