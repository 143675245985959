import { ReactElement } from "react";

import Contact from "./contact";

function AppFooter(): ReactElement {
  return (
    <footer className="fixed-bottom">
      <p>Made with love in San Francisco!</p>
      <Contact />
    </footer>
  );
}

export default AppFooter;
