import { ReactElement } from "react";

import { LinkedIn, MailOutline, Twitter } from "@mui/icons-material";
import { Paper, IconButton } from "@mui/material";

function Contact(): ReactElement {
  return (
    <Paper>
      <IconButton
        aria-label="primary"
        target="_blank"
        href="https://www.linkedin.com/in/rajeev-sarvaria"
        size="large"
      >
        <LinkedIn className="LinkedIn" />
      </IconButton>
      <IconButton
        aria-label="primary"
        target="_blank"
        href="https://www.twitter.com/jeevesls"
        size="large"
      >
        <Twitter className="Twitter" />
      </IconButton>
      <IconButton
        aria-label="primary"
        target="_blank"
        href="mailto:rajeev@sarvaria.ca"
        color="primary"
        size="large"
      >
        <MailOutline />
      </IconButton>
    </Paper>
  );
}

export default Contact;
