import { ReactElement, useEffect, useRef, useState } from "react";

import {
  TimelineConnector,
  TimelineContent,
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";
import {
  Typography,
  Theme,
  Tabs,
  Tab,
  Container,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { withStyles, CSSProperties } from "@mui/styles";

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  imageContainer: {
    maxWidth: "100%",
    width: "100%",
    paddingTop: "50%",
    position: "relative" as CSSProperties["position"],
    overflow: "hidden",
  },
  image: {
    position: "absolute" as CSSProperties["position"],
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover" as CSSProperties["objectFit"],
  },
  tabOrientation: {},
});

interface CareerProps {
  classes: {
    image: string;
    imageContainer: string;
    root: string;
  };
}

enum TabValue {
  Work = "resume-work",
  Education = "resume-education",
}

function JobTimelineItem(props: {
  title: string;
  team: string;
  group?: string;
}): ReactElement {
  return (
    <>
      <strong>{props.title}</strong>
      <br />
      {props.team}
      <br />
      {props.group}
    </>
  );
}

function Career({ classes }: CareerProps) {
  const [tabIndex, setIndex] = useState<TabValue>(TabValue.Work);
  const theme = useTheme();
  // Use useMediaQuery hook to check for viewport width
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  // Refs for the sections
  const workRef = useRef<HTMLDivElement>(null);
  const educationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const activeSections = new Set<TabValue>(); // Tracks active intersecting sections
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const tabValue = entry.target.getAttribute(
            "data-tabvalue"
          ) as TabValue;
          if (entry.isIntersecting) {
            activeSections.add(tabValue);
          } else {
            activeSections.delete(tabValue);
          }
        });

        // If the last intersecting section is visible, update the tab index
        if (activeSections.has(TabValue.Education)) {
          setIndex(TabValue.Education);
        } else if (activeSections.has(TabValue.Work)) {
          setIndex(TabValue.Work);
        }
      },
      // Maybe remove threshold?
      {
        root: null,
        threshold: 0.1, // trigger when 50% of the target is visible
      }
    );

    // Observing the sections
    if (workRef.current) {
      observer.observe(workRef.current);
    }
    if (educationRef.current) {
      observer.observe(educationRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: TabValue) => {
    setIndex(newValue);
    if (newValue === TabValue.Work && workRef.current) {
      workRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else if (newValue === TabValue.Education && educationRef.current) {
      educationRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Container className={classes.root}>
      <Box
        sx={{
          display: "flex",
          position: "sticky",
          top: 0,
          zIndex: theme.zIndex.appBar,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Tabs
          value={tabIndex}
          orientation={isPhone ? "horizontal" : "vertical"}
          onChange={handleChange}
        >
          <Tab value={TabValue.Work} label="Work" />
          <Tab value={TabValue.Education} label="Education" />
        </Tabs>
      </Box>
      <Box>
        <Box ref={workRef} data-tabvalue={TabValue.Work}>
          <Box>
            <div className={classes.imageContainer}>
              <picture>
                <source srcSet="media/chef.webp" type="image/webp" />
                <source srcSet="media/chef.jpeg" type="image/jpeg" />
                <img
                  src="media/chef.jpeg"
                  alt="Chef's logo"
                  className={classes.image}
                />
              </picture>
            </div>
            <Typography component="p" paragraph variant="body1">
              Largely working as a Full Stack Engineer to create the products
              necessary to setup, control, and understand what is happening on
              the Chef System after a production run. Additionally, working to
              understand user behaviours and needs to create the next iterations
              or brand new products.
            </Typography>
            <Typography variant="caption">
              Development stacks: React, Typescript, Express, Python, ROS
            </Typography>
          </Box>
          <Box>
            <div className={classes.imageContainer}>
              <picture>
                <source srcSet="media/yelp_front_desk.webp" type="image/webp" />
                <source srcSet="media/yelp_front_desk.jpeg" type="image/jpeg" />
                <img
                  src="media/yelp_front_desk.jpeg"
                  alt="Old front-desk of Yelp office"
                  className={classes.image}
                />
              </picture>
            </div>
            <Box>
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.3,
                  },
                }}
              >
                <TimelineItem>
                  <TimelineOppositeContent>
                    <JobTimelineItem
                      title="Engineering Manager"
                      team="Roots"
                      group="Consumer Growth"
                    />
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography component="p" paragraph variant="body1">
                      Roots was responsible for building out systems to enable
                      other Consumer and Growth teams to leverage our systems.
                      The mobile website was a large focus, migrating it to
                      React, Python 3, and microservices. Building on top of
                      this, we focused on mobile website page performance for
                      Core Web Vitals while collaborating across the Consumer
                      group to increase user conversions and SEO traffic. Other
                      systems was consumer authentication, third-party
                      integrations, and mobile app download attribution.
                    </Typography>
                    <Typography variant="caption">
                      Development stacks managed: Android, iOS, Python, React
                      <br />
                      Largest team size: 8
                      <br />
                      Locations: USA, UK
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent>
                    <JobTimelineItem
                      title="Engineering Manager"
                      team="Notifications-Infrastructure"
                      group="Consumer Growth"
                    />
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography component="p" paragraph variant="body1">
                      Managed this team on an interim basis while still managing
                      Growth Roots. Helped grow the team from three engineers in
                      San Francisco, to 6 engineers total across US, UK, and
                      Germany. Also, helped onboard and ramp-up the new manager
                      to take over the team. Notifications-Infrastructure was
                      responsible for a significant portion of the abstractions
                      on our push, email, and text systems in addition to
                      messaging optimizations through machine learning models.
                    </Typography>
                    <Typography variant="caption">
                      Development stacks managed: Python
                      <br />
                      Largest team size: 6
                      <br />
                      Locations: USA, Canada, UK, Germany
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent>
                    <JobTimelineItem
                      title="Engineering Manager"
                      team="Acquisition"
                      group="Consumer Growth"
                    />
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    Managed my previous team of 8 engineers. Worked with the
                    other Engineering and Product leaders across Consumer Growth
                    to re-organize the different team structures to better
                    enable the next few years of Consumer Growth. Team was
                    US-based only.
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent>
                    <JobTimelineItem
                      title="Software Engineering"
                      team="Acquisition"
                      group="Consumer Growth"
                    />
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="body1">
                      Worked across signup infrastructure, mobile and desktop
                      web, email infrastructure, and mobile clients. Focuses
                      were on acquiring and onboarding new users, and building
                      ability for Performance Marketing to further help spur
                      Consumer Growth.
                    </Typography>
                    <Typography variant="caption">
                      Technologies: Python, Cheetah, Java (Android),
                      Objective-C, Swift
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Box>
          </Box>
        </Box>
        <Box
          ref={educationRef}
          data-tabvalue={TabValue.Education}
          sx={{ textAlign: "left" }}
        >
          <Box>
            <div className={classes.imageContainer}>
              <picture>
                <source srcSet="media/uwaterloo_logo.webp" type="image/webp" />
                <source srcSet="media/uwaterloo_logo.png" type="image/png" />
                <img
                  src="media/uwaterloo_logo.png"
                  alt="University of Waterloo's logo"
                  className={classes.image}
                />
              </picture>
            </div>
            <Typography variant="h4">
              Computer Science, Bachelor&apos;s, University of Waterloo
            </Typography>
            <Typography variant="subtitle1">BCS / BBA Double Degree</Typography>
            <Typography variant="body1">Years attended: 2010-2015</Typography>
            <Box>
              <Typography variant="h6">Internships</Typography>
              <Typography variant="body2">
                Software Engineer, Yelp, San Francisco, CA
              </Typography>
              <Typography variant="body2">
                Software Developer, Pulse Energy, Vancouver, BC
              </Typography>
              <Typography variant="body2">
                Graphics Software Developer, BlackBerry, Waterloo, ON
              </Typography>
              <Typography variant="body2">
                Application Software Developer, Advanced Software Concepts,
                Ottawa, ON
              </Typography>
            </Box>
          </Box>
          <Box>
            <div className={classes.imageContainer}>
              <picture>
                <source srcSet="media/laurier_logo.webp" type="image/webp" />
                <source srcSet="media/laurier_logo.jpg" type="image/jpeg" />
                <img
                  src="media/laurier_logo.jpg"
                  alt="Wilfrid Laurier's logo"
                  className={classes.image}
                />
              </picture>
            </div>
            <Typography variant="h4">
              Business Administration, Bachelor&apos;s, Wilfrid Laurier
              University
            </Typography>
            <Typography variant="subtitle1">BCS / BBA Double Degree</Typography>
            <Typography variant="body1">Years attended: 2010-2015</Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default withStyles(styles)(Career);
