import { ReactElement } from "react";

import { Grid, Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { isWebpSupported } from "react-image-webp/dist/utils";

const useStyles = makeStyles((theme: Theme) => ({
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.2)",
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(9),
    },
  },
  headerFont: {
    "font-family": "'Indie Flower', cursive",
  },
}));

export default function HeaderCard(): ReactElement {
  const classes = useStyles();
  const backgroundURL = isWebpSupported()
    ? "media/tahoe.webp"
    : "media/tahoe.jpg";

  return (
    <Paper
      className={classes.mainFeaturedPost}
      style={{ backgroundImage: `url(${backgroundURL})` }}
    >
      <div className={classes.overlay} />
      <Grid container justifyContent="center">
        <Grid item lg={12} className={classes.mainFeaturedPostContent}>
          <Typography
            component="h1"
            variant="h1"
            color="inherit"
            className={classes.headerFont}
          >
            Rajeev Lloyd Sarvaria
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
