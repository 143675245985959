import { useState, useEffect, ReactElement } from "react";

import { AccountBox, WorkOutline } from "@mui/icons-material";
import { Tabs, Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import routes from "components/routes";

function NavigationTabs(): ReactElement {
  const location = useLocation();
  const [index, setIndex] = useState<number>(
    routes.findIndex((route) => route.path === location.pathname)
  );

  useEffect(() => {
    const currentIndex = routes.findIndex(
      (route) => route.path === location.pathname
    );
    setIndex(currentIndex);
  }, [location]);

  return (
    <Tabs indicatorColor="secondary" value={index} textColor="primary" centered>
      <Tab icon={<AccountBox />} label="About" to="/" component={Link} />
      <Tab
        icon={<WorkOutline />}
        label="Career"
        to="/career"
        component={Link}
      />
    </Tabs>
  );
}

export default NavigationTabs;
